'use strict';

angular.module('windmanagerApp')
  .directive('printFindings', function () {
    return {
      templateUrl: 'components/directives/finding/printFindings/printFindings.html',
      restrict: 'EA',
      scope: {
        site: '='
      },
      controller: 'PrintFindingsCtrl',
      controllerAs: 'printFindings',
      link: function (scope, element, attrs) {
      }
    };
  });
